<template>
        <div class="carousel-table">
            <el-table
              :data="lsit"
              border
              style="width: 100%">
              <el-table-column
                prop="position"
                label="位置"
                width="200">
                <template slot-scope="scope">
                  <span>{{scope.row.position==0?"上":"下"}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="img"
                label="图片"
                width="200">
                <template slot-scope="scope">
                  <img :src="scope.row.img" alt="" style="width:150px">
                </template>
              </el-table-column>
              <el-table-column
                prop="articleTitle"
                label="被关联文章"
                width="230">
              </el-table-column>
              <el-table-column
                label="操作"
                width="230">
                <template slot-scope="scope">
                  <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                  <el-button @click="relation(scope.row)"  type="text" size="small">关联文章</el-button>
                </template>
              </el-table-column>
            </el-table>
      </div>
</template>

<script>
import {QUERY,UPDATE}  from '@/services/dao.js';
export default {
    data(){
        return{
            lsit: []
        }
    },
    created(){
      this.init();
    },
    methods:{
       async init(){
          let info=await QUERY("POST","",'TtCarouselAssistant {     article     img     position     id       TtArticle {title }}');
          info.data.TtCarouselAssistant.forEach(element => {
            this.lsit.push({id:element.id,position:element.position,img:element.img,articleId:element.article,articleTitle: element.TtArticle==null?"":element.TtArticle.title});
          });
        },
        //关联文章
        relation(row){
            if(row.id<0){
                //alert("请先编辑轮播图");
                this.$message({
                  message: '请先编辑轮播图',
                  type: 'warning'
                });
                return;
            }
            this.$router.push({path:"carouselAssistantRelation",query:{
                id:row.id,
                position:row.position
            }})
        },
        //编辑
        edit(row){
            this.$router.push({path:"editCarouselAssistant",query:{
                id:row.id,
                position:row.position
            }})
        }   
    }
}
</script>

<style>

</style>